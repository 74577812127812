/* global $ */
import '../../css/app/components/wishList.scss';
import { QuantitySelector } from '../components/quantitySelector';

class WishList
{
    constructor() 
    {
        let quantitySelector;

        $.each($('.quantity-item'), (i, elem) => {
            quantitySelector = new QuantitySelector;
            quantitySelector.init($(elem), 0);
        });

        this.initWishlist($('.action-wishlist'));
        console.log('Wish List initialised')
    }

    initWishlist($elem)
    {
        $elem.find('.js-add-to-wishlist').on('click', function(e) {
            e.preventDefault();
            const elementId = $(e.target).data('item')
            let data = {
                elementId: elementId
            };
            $.ajax({
                method: 'post',
                url: '/?action=wishlist/items/add',
                data: data,
                cache: false,
                dataType: 'json',
                headers: {
                    "X-CSRF-Token": window.Globals.csrfToken
                },
            }).done((data) => {
                
                if (data.success) {
                    this.showButton($(e.target).parent(), 'remove')
                }
            });
        }.bind(this));

        $elem.find('.js-remove-to-wishlist').on('click', function(e) {
            e.preventDefault();
            const elementId = $(e.target).data('item')
            let data = {
                elementId: elementId
            };
            $.ajax({
                method: 'post',
                url: '/?action=wishlist/items/remove',
                data: data,
                cache: false,
                dataType: 'json',
                headers: {
                    "X-CSRF-Token": window.Globals.csrfToken
                },
            }).done((data) => {
                if (data.success) {
                    this.showButton($(e.target).parent(), 'add')
                }
            });
        }.bind(this));
    }

    showButton (el, action) {
        if (action == 'add') {
            el.find('.js-add-to-wishlist').removeClass('hide-wishlist-button')
            el.find('.js-remove-to-wishlist').addClass('hide-wishlist-button')
        } else {
            el.find('.js-add-to-wishlist').addClass('hide-wishlist-button')
            el.find('.js-remove-to-wishlist').removeClass('hide-wishlist-button')
        }
    }
}

new WishList;