import "../../css/app/components/quantity-selector.scss";

class QuantitySelector
{
    $elem;
    $is_min;

    init($elem, is_min = 1)
    {
        this.$elem = $elem;
        this.is_min = is_min;
        $elem.find('.js-less-qty').click((e) => {
            e.preventDefault();
            this.addQuantity(-1);
        });
        $elem.find('.js-more-qty').click((e) => {
            e.preventDefault();
            this.addQuantity(1);
        });
        $elem.find('.js-qty').keyup(() => {
            this.checkAndUpdate();
        }).change(() => {
            this.checkAndUpdate();
        });
    }

    updatePrice()
    {
        let $qty = this.$elem.find('.js-qty');
        let $price = this.$elem.find('.js-price');
        let basePrice = parseFloat($price.data('price'));
        let price = basePrice * parseInt($qty.val());
        $price.html(price.toFixed(2));
    }

    addQuantity(amount)
    {
        let $qty = this.$elem.find('.js-qty');
        let qty = parseInt($qty.val());
        let newQty = qty + amount;
        let max = parseInt($qty.attr('max'));
        if (newQty < 1) {
            if (this.is_min == 0) {
                $qty.val(0);
            } else {
                $qty.val(1);
            }
        } else if (newQty > max || max == -1) {
            $qty.val(max);
        } else {
            $qty.val(newQty);
        }
        this.updatePrice();
    }

    checkAndUpdate()
    {
        let $qty = this.$elem.find('.js-qty');
        let qty = parseInt($qty.val());
        let max = parseInt($qty.attr('max'));
        if (isNaN(qty) || qty < 1) {
            if (this.is_min == 0) {
                $qty.val(0);
            } else {
                $qty.val(1);
            }
        } else if (qty > max || max == -1) {
            $qty.val(max);
        }
        this.updatePrice();
    }
}

export { QuantitySelector };